/* eslint-disable */
export const FUND_ALLOCATION_TABLE_SCHEMA = {
  'Journal Entity Type': {
    prop: 'journalEntityType',
    type: {
      'Journal Entity Type': {
        prop: 'key',
        type: String,
      }
    }
  },
  'FundJournal': {
    prop: 'fundJournal',
    type: {
      'Type Of Transfer': {
        prop: 'typeOfTransfer',
        type: {
          'Type Of Transfer': {
            prop: 'key',
            type: String
          }
        },
      },
      'Type Of Journal': {
        prop: 'typeOfJournal',
        type: {
          'Type Of Journal': {
            prop: 'key',
            type: String,
          }
        }
      },
      'From': {
        prop: 'from',
        type: {
          'From Client Number': {
            prop: 'clientNumber',
            type: String,
          },
          'From Billing Centre': {
            prop: 'billingCentre',
            type: String,
          },
          'From Fund Number': {
            prop: 'fundNumber',
            type: String,
          },
          'From Vehicle Number': {
            prop: 'vehicleNumber',
            type: String,
          },
        }
      },
      'To': {
        prop: 'to',
        type: {
          'To Client Number': {
            prop: 'clientNumber',
            type: String,
          },
          'To Billing Centre': {
            prop: 'billingCentre',
            type: String,
          },
          'To Fund Number': {
            prop: 'fundNumber',
            type: String,
          },
          'To Vehicle Number': {
            prop: 'vehicleNumber',
            type: String,
          },
        }
      },
      'Income Amount': {
        prop: 'incomeAmount',
        type: String,
      },
      'Expenses Amount': {
        prop: 'expensesAmount',
        type: String,
      },
      'Interest Amount': {
        prop: 'interestAmount',
        type: String,
      },
    },
  },
  'ClientJournal': {
    prop: 'clientJournal',
    type: {
      'Transaction Code': {
        prop: 'transactionCode',
        type: String,
      },
      'VAT Indicator': {
        prop: 'vatIndicator',
        type: String,
      },
      'GL Account': {
        prop: 'glAccountNumber',
        type: String,
      },
      'From Client Number': {
        prop: 'clientNumber',
        type: String,
      },
      'From Billing Centre': {
        prop: 'billingCentre',
        type: String,
      },
      'Transfer Amount': {
        prop: 'transferAmount',
        type: String,
      },
    }
  },
  Narration: {
    prop: 'narration',
    type: String,
  },
  Date: {
    prop: 'journalDate',
    type: Date,
  },
  'Ref/Reg': {
    prop: 'refReg',
    type: String,
  },
};
