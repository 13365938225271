<template>
  <ValidationProvider v-slot="{errors, classes}"
                      :name="label"
                      :rules="rules">
    <sb-input :label="label"
              :error="classes.invalid">
      <v-textarea v-model="innerValue"
                  :error-messages="errors"
                  outlined
                  v-bind="$attrs"
                  v-on="$listeners" />
    </sb-input>
  </ValidationProvider>
</template>

<script>
import inputBase from '@/mixins/inputBase';

export default {
  components: {},

  mixins: [inputBase],
  watch: {
    innerValue(value) {
      const data = {
        label: this.label,
        value,
      };
      this.$emit('input-with-label', data);
    },
  },
};
</script>
