<template>
  <v-card hover
          tile
          :color="color"
          :max-width="maxWidth"
          :min-width="minWidth"
          :class="{'noclick': !clickable}">
    <slot name="title">
      <v-card-title v-if="title">
        <sb-title :title="title" />
      </v-card-title>
    </slot>
    <v-card-text v-if="!disablePadding"
                 class="pb-0">
      <v-row v-if="loading"
             justify="center">
        <sb-loader />
      </v-row>
      <slot v-if="!loading" />
    </v-card-text>
    <slot v-if="disablePadding && !loading" />
    <v-card-actions>
      <slot name="actions" />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: String,
      default: '',
    },
    minWidth: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    disablePadding: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '',
    },
  },
};
</script>
