<template>
  <ValidationProvider v-slot="{errors, classes}"
                      :name="label"
                      :rules="rules">
    <sb-input v-if="!sbSolo"
              :label="label"
              :error="classes.invalid">
      <v-text-field v-model="innerValue"
                    :error-messages="errors"
                    outlined
                    single-line
                    dense
                    v-bind="$attrs"
                    v-on="$listeners"
                    @keydown="keyDown" />
    </sb-input>
    <v-text-field v-else
                  v-model="innerValue"
                  :label="label"
                  :error="classes.invalid"
                  :error-messages="errors"
                  solo
                  v-bind="$attrs"
                  v-on="$listeners"
                  @keydown="keyDown" />
  </ValidationProvider>
</template>

<script>
import inputBase from '@/mixins/inputBase';

export default {
  mixins: [inputBase],
  data() {
    return {
      innerValue: '',
    };
  },
  watch: {
    innerValue(value) {
      const data = {
        label: this.label,
        value,
      };
      this.$emit('input-with-label', data);
    },
  },
  methods: {
    keyDown(event) {
      if (event.key === 'Enter') {
        this.$emit('enter');
      }
    },
  },
};
</script>
