<template>
  <ValidationProvider v-slot="{errors, classes}"
                      :name="label"
                      :rules="rules">
    <sb-input :label="label"
              :error="classes.invalid">
      <v-select v-model="innerValue"
                :hide-details="errors.length === 0"
                :error-messages="errors"
                outlined
                dense
                v-bind="$attrs"
                v-on="$listeners" />
    </sb-input>
  </ValidationProvider>
</template>

<script>
import inputBase from '@/mixins/inputBase';

export default {
  mixins: [inputBase],

  watch: {
    innerValue(value) {
      const data = {
        label: this.label,
        value,
      };
      this.$emit('input-with-label', data);
    },
  },
};
</script>
