<template>
  <v-container class="fill-height"
               fluid>
    <v-row justify="center"
           align="center">
      <v-progress-circular indeterminate
                           :size="size"
                           :width="width"
                           :color="color" />
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 80,
    },
    width: {
      type: Number,
      default: 6,
    },
    color: {
      type: String,
      default: '#F86700',
    },
  },
};
</script>
