<template>
  <ValidationProvider v-slot="{errors}"
                      :name="label"
                      :rules="rules">
    <v-file-input v-model="innerValue"
                  :disabled="$wait.any"
                  :accept="types"
                  :error-messages="errors"
                  :counter="showFileCounter"
                  :multiple="isMultipleFileSelection"
                  :label="label"
                  color="primary"
                  :placeholder="placeholder"
                  prepend-icon=""
                  prepend-inner-icon="mdi-paperclip"
                  outlined
                  :show-size="1000">
      <template #selection="{text}">
        <v-chip color="secondary"
                dark
                label
                small>
          {{ text }}
        </v-chip>
      </template>
    </v-file-input>
  </ValidationProvider>
</template>
<script>
import { ValidationProvider } from 'vee-validate';

export default {
  components: {
    ValidationProvider,
  },
  inheritAttrs: false,

  props: {
    value: {
      type: [Array, File],
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    fileTypes: {
      type: String,
      default: '',
    },
    showFileCounter: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Select your file',
    },
    isMultipleFileSelection: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [Object, String],
      default: undefined,
    },
  },
  data() {
    return {
      types: this.fileTypes,
      innerValue: [],
    };
  },
  watch: {
    // Handles Internal Model Changes.
    innerValue(newVal) {
      let inputValue = newVal;
      if (inputValue) {
        if (inputValue.constructor === Array) {
          this.innerValue = inputValue;
        } else {
          this.innerValue = [inputValue];
        }
      } else {
        inputValue = [];
      }
      this.$emit('input', inputValue);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
};
</script>
