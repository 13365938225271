export const REQUEST_TYPE_LIST = {
  SR_REQUEST_TYPE_REQUEST: {
    key: 'SR_REQUEST_TYPE_REQUEST',
    value: 'Request',
  },
  SR_REQUEST_TYPE_PROBLEM: {
    key: 'SR_REQUEST_TYPE_PROBLEM',
    value: 'Problem',
    disabled: true,
  },
  SR_REQUEST_TYPE_SERVICE: {
    key: 'SR_REQUEST_TYPE_SERVICE',
    value: 'Question',
    disabled: true,
  },
  SR_REQUEST_TYPE_UNKNOWN: {
    key: 'SR_REQUEST_TYPE_UNKNOWN',
    value: 'Unknown',
    hidden: true,
  },
};

export const SERVICE_TYPE_LIST = {
  SR_SERVICE_TYPE_MAINTENANCE_AUTHORISATION_PORTAL: {
    key: 'SR_SERVICE_TYPE_MAINTENANCE_AUTHORISATION_PORTAL',
    value: 'Maintenance Authorisation Portal',
    hidden: true,
  },
  SR_SERVICE_TYPE_MAINTENANCE_AUTHORISATION: {
    key: 'SR_SERVICE_TYPE_MAINTENANCE_AUTHORISATION',
    value: 'Maintenance Authorisation',
  },
  SR_SERVICE_TYPE_MAINTENANCE_MANAGEMENT: {
    key: 'SR_SERVICE_TYPE_MAINTENANCE_MANAGEMENT',
    value: 'Maintenance Management',
    hidden: true,
  },
  SR_SERVICE_TYPE_ROADSIDE_ASSISTANCE: {
    key: 'SR_SERVICE_TYPE_ROADSIDE_ASSISTANCE',
    value: 'Roadside Assistance',
    disabled: true,
  },
  SR_SERVICE_TYPE_INCIDENT_MANAGEMENT: {
    key: 'SR_SERVICE_TYPE_INCIDENT_MANAGEMENT',
    value: 'Incident Management',
    disabled: true,
  },
  SR_SERVICE_TYPE_ACCIDENT_MANAGEMENT: {
    key: 'SR_SERVICE_TYPE_ACCIDENT_MANAGEMENT',
    value: 'Accident Management',
    disabled: true,
  },
  SR_SERVICE_TYPE_UNKNOWN: {
    key: 'SR_SERVICE_TYPE_UNKNOWN',
    value: 'Unknown',
    hidden: true,
  },
  SR_SERVICE_TYPE_ELECTRONIC_AUTHORISATION: {
    key: 'SR_SERVICE_TYPE_ELECTRONIC_AUTHORISATION',
    value: 'Electronic Authorisation',
    hidden: true,
  },
};

export const REQUEST_TYPES = {
  REQUEST: {
    value: 'REQUEST',
    text: 'Request',
  },
  PROBLEM: {
    value: 'PROBLEM',
    text: 'Problem',
    disabled: true,
  },
  QUESTION: {
    value: 'QUESTION',
    text: 'Question',
    disabled: true,
  },
};

export const SERVICE_TYPES = {
  MANAGED_MAINTENANCE: {
    value: 'MANAGED_MAINTENANCE',
    text: 'Managed Maintenance',
  },
  ROADSIDE_ASSISTANCE: {
    value: 'ROADSIDE_ASSISTANCE',
    text: 'Roadside Assistance',
    disabled: true,
  },
  INCIDENT_MANAGEMENT: {
    value: 'INCIDENT_MANAGEMENT',
    text: 'Incident or Accident',
    disabled: true,
  },
};

export const PROCESSES = {
  ROADSIDE_ASSISTANCE: {
    name: 'Road Side Assistance',
    value: 'ROADSIDE_ASSISTANCE',
    processModelId: 'SbMbfSlamRaProcess/RoadsideAssistance',
  },
  INCIDENT_MANAGEMENT: {
    name: 'Incident Accident Management',
    value: 'INCIDENT_MANAGEMENT',
    processModelId: 'SbMbfSlamImProcess/IncidentManagement',
  },
  MANAGED_MAINTENANCE: {
    name: 'Managed Maintenance',
    value: 'MANAGED_MAINTENANCE',
    processModelId: 'SbMbfSlamMmProcess/ManagedMaintenance',
  },
};

export const CALLER_TYPES = {
  CUSTOMER: {
    value: 'CUSTOMER',
    text: 'Customer',
  },
  MERCHANT: {
    value: 'MERCHANT',
    text: 'Merchant',
  },
};

export const SUPPLIER_RESPONSES = {
  CONTINUE: {
    value: 'CONTINUE',
    text: 'Continue',
  },
  ASSESSOR_APPOINTED: {
    value: 'ASSESSOR_APPOINTED',
    text: 'Assessor Appointed',
  },
  REJECTED: {
    value: 'REJECTED',
    text: 'Rejected',
  },
};

export const FILE_ICONS = {
  '7z': 'file-7z.png',
  bmp: 'file-bmp.png',
  csv: 'file-csv.png',
  doc: 'file-doc.png',
  docx: 'file-docx.png',
  gif: 'file-gif.png',
  gz: 'file-gz.png',
  jpg: 'file-jpg.png',
  jpeg: 'file-jpg.png',
  pdf: 'file-pdf.png',
  png: 'file-png.png',
  rar: 'file-rar.png',
  tar: 'file-tar.png',
  tiff: 'file-tiff.png',
  txt: 'file-txt.png',
  xlsx: 'file-xls.png',
  xls: 'file-xls.png',
  zip: 'file-zip.png',
  unknown: 'file-unknown.png',
};

export const SEVERITIES = {
  LOW: {
    value: 'LOW',
    text: 'Low',
    sortOrder: 3,
  },
  MEDIUM: {
    value: 'MEDIUM',
    text: 'Medium',
    sortOrder: 2,
  },
  HIGH: {
    value: 'HIGH',
    text: 'High',
    sortOrder: 1,
  },
};

export const SEVERITY_COLORS = {
  LOW: 'success',
  MEDIUM: 'warning',
  HIGH: 'error',
};

export const VEHICLE_UPLOAD_STATUS_COLORS = {
  COMPLETE: 'success',
  SAVED: 'success',
  VALIDATION_FAILED: 'error',
};
export const AUDIT_TRAIL_COLORS = {
  success: 'success',
  failed: 'error',
};

export const AUTH_STATUSES = {
  IN_PROGRESS: {
    value: 'In Progress',
    text: 'In Progress',
    sortOrder: 4,
  },
  VALIDATION_ERROR: {
    value: 'Validation Error',
    text: 'Validation Error',
    sortOrder: 2,
  },
  VALIDATION_FAILED: {
    value: 'Validation Failed',
    text: 'Validation Failed',
    sortOrder: 1,
  },
  AUTHORISATION_FAILED: {
    value: 'Authorisation Failed',
    text: 'Authorisation Failed',
    sortOrder: 3,
  },
  AUTHORISED: {
    value: 'Authorised',
    text: 'Authorised',
    sortOrder: 5,
  },
  AUTHORISATION_CAPTURED_NOT_AUTHORISED: {
    value: 'Authorisation Captured not Authorised',
    text: 'Authorisation Captured not Authorised',
    sortOrder: 6,
  },
  INVOICED: {
    value: 'Invoiced',
    text: 'Invoiced',
    sortOrder: 7,
  },
  COMPLETED: {
    value: 'Completed',
    text: 'Completed',
    sortOrder: 8,
  },
  CANCELLED: {
    value: 'Cancelled',
    text: 'Cancelled',
    sortOrder: 9,
  },
  ERROR: {
    value: 'Error',
    text: 'Error',
    sortOrder: 10,
  },
};

export const AUTH_STATUS_COLORS = {
  Invoiced: 'success',
  Completed: 'success',
  Authorised: 'success',
  'Validation Failed': 'warning',
  'Authorisation Failed': 'warning',
  'Authorisation Captured not Authorised': 'error',
  'Validation Error': 'error',
  Cancelled: 'error',
  Error: 'error',
  'In Progress': 'info',
};

export const FUND_STATUS_COLORS = {
  Completed: 'success',
  'Pending Processing': 'primary',
  'Validation Failed': 'error',
  'Insufficient funds': 'warning',
  Saved: 'success',
  Declined: 'error',
  Submitted: 'success',
  'Pending Approval': 'info',
  'Awaiting Documents': 'warning',
  Cancelled: 'warning',
};

export const STATUS_COLORS = {
  Completed: 'success',
  'Pending Processing': 'primary',
  'Validation Failed': 'error',
  'Insufficient funds': 'warning',
  'In-Progress': 'primary',
  Saved: 'success',
  Declined: 'error',
  Submitted: 'success',
  'Pending Approval': 'info',
  'Awaiting Documents': 'warning',
  Cancelled: 'warning',
};

export const SR_APPLICATION_TYPES = {
  SR_APPLICATION_TYPE_SLAM: 'SR_APPLICATION_TYPE_SLAM',
  SR_APPLICATION_TYPE_AUTHORISATIONS: 'SR_APPLICATION_TYPE_AUTHORISATIONS',
  SR_APPLICATION_TYPE_FUND_ALLOCATION: 'SR_APPLICATION_TYPE_FUND_ALLOCATION',
  SR_APPLICATION_TYPE_VEHICLE_CAPTURE: 'SR_APPLICATION_TYPE_VEHICLE_CAPTURE',
};

export const SR_STATUSES = {
  SR_STATUS_ALL: {
    value: 'SR_STATUS_ALL',
    text: 'All',
    hidden: true,
  },
  SR_STATUS_CREATED: {
    value: 'SR_STATUS_CREATED',
    text: 'Created',
    hidden: true,
  },
  SR_STATUS_OPEN: {
    value: 'SR_STATUS_OPEN',
    text: 'Open',
  },
  SR_STATUS_IN_PROGRESS: {
    value: 'SR_STATUS_IN_PROGRESS',
    text: 'In-Progress',
    hidden: true,
  },
  SR_STATUS_CLOSED: {
    value: 'SR_STATUS_CLOSED',
    text: 'Closed',
  },
  SR_STATUS_COMPLETED: {
    value: 'SR_STATUS_COMPLETED',
    text: 'Completed',
    hidden: true,
  },
  SR_STATUS_CANCELLED: {
    value: 'SR_STATUS_CANCELLED',
    text: 'Cancelled',
    hidden: true,
  },
  SR_STATUS_SAVED: {
    value: 'SR_STATUS_SAVED',
    text: 'Saved',
    hidden: true,
  },
  SR_STATUS_PENDING_APPROVAL: {
    value: 'SR_STATUS_PENDING_APPROVAL',
    text: 'Pending Approval',
    hidden: true,
  },
  SR_STATUS_OVERRIDE: {
    value: 'SR_STATUS_OVERRIDE',
    text: 'Override',
    hidden: true,
  },
  SR_STATUS_DECLINED: {
    value: 'SR_STATUS_DECLINED',
    text: 'Declined',
    hidden: true,
  },
  SR_STATUS_PENDING_PROCESSING: {
    value: 'SR_STATUS_PENDING_PROCESSING',
    text: 'Pending Processing',
    hidden: true,
  },
  SR_STATUS_VALIDATION_FAILED: {
    value: 'SR_STATUS_VALIDATION_FAILED',
    text: 'Validation Failed',
    hidden: true,
  },
  SR_STATUS_AWAITING_DOCUMENTS: {
    value: 'SR_STATUS_AWAITING_DOCUMENTS',
    text: 'Awaiting Documents',
    hidden: true,
  },
};

export const SR_STATES = {
  SR_STATE_OPEN: 'SR_STATE_OPEN',
  SR_STATE_CREATED: 'SR_STATE_CREATED',
  SR_STATE_MERCHANT_MANDATE_VALIDATION: 'SR_STATE_MERCHANT_MANDATE_VALIDATION',
  SR_STATE_CUSTOMER_MANDATE_VALIDATION: 'SR_STATE_CUSTOMER_MANDATE_VALIDATION',
  SR_STATE_AUTHORISATION_VALIDATION: 'SR_STATE_AUTHORISATION_VALIDATION',
  SR_STATE_RESUBMITTED: 'SR_STATE_RESUBMITTED',
  SR_STATE_INVOICED: 'SR_STATE_INVOICED',
  SR_STATE_COMPLETED: 'SR_STATE_COMPLETED',
  SR_STATE_ERROR: 'SR_STATE_ERROR',
  SR_STATE_AUTHORISED: 'SR_STATE_AUTHORISED',
  SR_STATE_CUSTOMER_RATING: 'SR_STATE_CUSTOMER_RATING',
  SR_STATE_CANCELLED: 'SR_STATE_CANCELLED',
};

export const TASK_STATUSES = {
  TASK_STATUS_OPEN: {
    value: 'TASK_STATUS_OPEN',
    text: 'Open',
  },
  TASK_STATUS_CLOSED: {
    value: 'TASK_STATUS_CLOSED',
    text: 'Closed',
  },
  TASK_STATUS_CANCELLED: {
    value: 'TASK_STATUS_CANCELLED',
    text: 'Closed',
  },
};

export const AUTHORISATION_TYPES = {
  FMS: {
    value: 'FMS',
    text: 'FMS',
  },
  CLIENT: {
    value: 'CLIENT',
    text: 'Client',
  },
};

export const SR_FILTER_KEYS = ['serviceRequestNumber', 'serviceType.value', 'createdTimestamp', 'externalReferenceStatus', 'severity', 'customer.name', 'vehicle.registrationNumber', 'externalReferenceId', 'currentTask', 'authorisationDetail.authorisationstatus', 'authorisationDetail.authorisationNumber', 'authorisationDetail.authorisationMessage'];

export const MODEL = {
  ATTACHMENT: 'ATTACHMENT',
  CONTACT_DETAIL: 'CONTACT_DETAIL',
  CUSTOMER: 'CUSTOMER',
  DIAGNOSIS: 'DIAGNOSIS',
  DRIVER_DETAILS: 'DRIVER_DETAILS',
  EVENT: 'EVENT',
  EVENT_SNAPSHOT: 'EVENT_SNAPSHOT',
  LOCATION: 'LOCATION',
  MANAGED_AUTHORISATION: 'MANAGED_AUTHORISATION',
  MERCHANT: 'MERCHANT',
  NOTE: 'NOTE',
  RECOVERY_VEHICLE_DETAILS: 'RECOVERY_VEHICLE_DETAILS',
  REFERENCE_LIST: 'REFERENCE_LIST',
  SERVICE_REQUEST: 'SERVICE_REQUEST',
  TASK: 'TASK',
  TECHNICIAN_DETAILS: 'TECHNICIAN_DETAILS',
  TOWING_ASSISTANCE_DETAILS: 'TOWING_ASSISTANCE_DETAILS',
  VEHICLE: 'VEHICLE',
  VEHICLE_DETAILS: 'VEHICLE_DETAILS',
};

export const LOG_TYPES = {
  ERROR: {
    value: 'ERROR',
    text: 'Error',
  },
  INFO: {
    value: 'INFO',
    text: 'Info',
  },
};
export const BULK_SINGLE_VEHICLE_UPLOAD_HEADERS = {
  merchantLimit: [
    {
      text: 'Merchant Type Code',
      value: 'merchantTypeCode',
    },
    {
      text: 'Merchant Type Description',
      value: 'merchantTypeDescription',
    },
    {
      text: 'Daily Trans Count Limit Default',
      value: 'dailyTransCountLimitDFLT',
    },
    {
      text: 'Daily Trans Count Limit Overide',
      value: 'dailyTransCountLimitOVRD',
    },
    {
      text: 'Min Hours BTWN TRNS Default',
      value: 'minHoursDFLT',
    },
    {
      text: 'Min Hours BTWN TRNS Overide',
      value: 'minHoursOVRD',
    },
    {
      text: 'Transaction Value Limit Default',
      value: 'transactionValueLimitDefault',
    },
    {
      text: 'Transaction Value Limit Override',
      value: 'transactionValueLimitOverride',
    },
    {
      text: 'Actions',
      value: 'actions',
    },
  ],
  valueAddProducts: [
    {
      text: 'Product Code',
      value: 'productCode',
    },
    {
      text: 'Description',
      value: 'productDescription',
    },
    {
      text: 'Instalment Fee',
      value: 'instalmentFee',
    },
    {
      text: 'Instalment Month',
      value: 'instalmentMonth',
    },
    {
      text: 'Service Fee',
      value: 'serviceFee',
    },
    {
      text: 'Effective Date',
      value: 'effectiveDate',
      width: 190,
    },
    {
      text: 'Driver Allowance',
      value: 'driverAllowance',
    },
    {
      text: 'Driver Billing',
      value: 'driverBilling',
    },

    {
      text: 'Actions',
      value: 'actions',
    },
  ],
  vehicleInfo: [
    {
      text: 'Center Number',
      value: 'vehicleInformation.centreNumber',
    },
    {
      text: 'Vehicle Make',
      value: 'vehicleInformation.vehicleMake',
    },
    {
      text: 'Vehicle Description',
      value: 'vehicleInformation.vehicleModel',
    },
    {
      text: 'Vehicle Year',
      value: 'vehicleInformation.vehicleYear',
    },
    {
      text: 'Vehicle Registration',
      value: 'vehicleInformation.vehicleRegistrationNumber',
    },

    {
      text: 'New/Used',
      value: 'vehicleInformation.isNewOrUsed',
    },

    {
      text: 'Status',
      value: 'vehicleInformation.status',
    },

    {
      text: 'Live Date & Time',
      value: 'vehicleInformation.liveDateAndTime',
    },

  ],
};

export const CONTRACT_CATEGORY_RESTRICTIONS_HEADERS = [
  {
    text: 'Category Code',
  },
  {
    text: 'Category Description',
  },
];

export const CONTRACT_CATEGORY_LIMITS_HEADERS = [
  {
    text: 'Category Code',
  },
  {
    text: 'Category Description',
  },
  {
    text: 'Front Allow',
  },
  {
    text: 'Rear Allow',
  },
  {
    text: 'Tag Axle Allow',
  },
  {
    text: 'Other Allow',
  },
];

export const STANDARD_RATE_HEADERS = [
  {
    text: 'Calculated (Incl. Admin) Frm',
  },
  {
    text: 'Calculated (Incl. Admin) To',
  },
  {
    text: 'Calculated (Incl. Admin) Rate',
  },
  {
    text: 'Calculated (Incl. Admin) CPK',
  },
  {
    text: 'Overridden (Incl. Admin) Frm',
  },
  {
    text: 'Overridden (Incl. Admin) To',
  },
  {
    text: 'Overridden (Incl. Admin) Rate',
  },
  {
    text: 'Overridden (Incl. Admin) CPK',
  },
  {
    text: 'Rate Difference',
  },
];

export const AUTH_TABLE_HEADERS = {
  tyres: [
    {
      text: 'Part No',
      align: 'start',
      sortable: false,
      value: 'partNo',
      width: 130,
    },
    {
      text: 'Description',
      value: 'description',
    },
    {
      text: 'Qty',
      value: 'qty',
      align: 'center',
      width: 90,
    },
    {
      text: 'Retail Price',
      value: 'retailPriceExclVat',
      align: 'end',
    },
    {
      text: 'Discount %',
      value: 'discountPercent',
      align: 'end',
      width: 90,
    },
    {
      text: 'Special Price',
      value: 'specialPriceExclVat',
      align: 'end',
      width: 170,
    },
    {
      text: 'Total',
      value: 'totalNetAmount',
      align: 'end',
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false,
      align: 'center',
    },
    // { text: 'Tyre Position', value: 'tyrePosition' },
    // { text: 'Tyre Serial', value: 'serialNo' },
    // { text: 'Tread Depth', value: 'treadDepth' },
  ],
  brakes: [
    {
      text: 'Part No',
      align: 'start',
      sortable: false,
      value: 'partNo',
      width: 130,
    },
    {
      text: 'Description',
      value: 'description',
    },
    {
      text: 'Qty',
      value: 'qty',
      align: 'center',
      width: 90,
    },
    {
      text: 'Retail Price',
      value: 'retailPriceExclVat',
      align: 'end',
    },
    {
      text: 'Discount %',
      value: 'discountPercent',
      align: 'end',
      width: 90,
    },
    {
      text: 'Special Price',
      value: 'specialPriceExclVat',
      align: 'end',
      width: 170,
    },
    {
      text: 'Total',
      value: 'totalNetAmount',
      align: 'end',
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ],
  batteries: [
    {
      text: 'Part No',
      align: 'start',
      sortable: false,
      value: 'partNo',
      width: 130,
    },
    {
      text: 'Description',
      value: 'description',
    },
    {
      text: 'Qty',
      value: 'qty',
      align: 'center',
      width: 90,
    },
    {
      text: 'Retail Price',
      value: 'retailPriceExclVat',
      align: 'end',
    },
    {
      text: 'Discount %',
      value: 'discountPercent',
      align: 'end',
      width: 90,
    },
    {
      text: 'Special Price',
      value: 'specialPriceExclVat',
      align: 'end',
      width: 170,
    },
    {
      text: 'Total',
      value: 'totalNetAmount',
      align: 'end',
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ],
  shocks: [
    {
      text: 'Part No',
      align: 'start',
      sortable: false,
      value: 'partNo',
      width: 130,
    },
    {
      text: 'Description',
      value: 'description',
    },
    {
      text: 'Qty',
      value: 'qty',
      align: 'center',
      width: 90,
    },
    {
      text: 'Retail Price',
      value: 'retailPriceExclVat',
      align: 'end',
    },
    {
      text: 'Discount %',
      value: 'discountPercent',
      align: 'end',
      width: 90,
    },
    {
      text: 'Special Price',
      value: 'specialPriceExclVat',
      align: 'end',
      width: 170,
    },
    {
      text: 'Total',
      value: 'totalNetAmount',
      align: 'end',
      width: 170,
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ],
  exhausts: [
    {
      text: 'Part No',
      align: 'start',
      sortable: false,
      value: 'partNo',
      width: 130,
    },
    {
      text: 'Description',
      value: 'description',
    },
    {
      text: 'Qty',
      value: 'qty',
      align: 'center',
      width: 90,
    },
    {
      text: 'Retail Price',
      value: 'retailPriceExclVat',
      align: 'end',
      width: 170,
    },
    {
      text: 'Discount %',
      value: 'discountPercent',
      align: 'end',
      width: 116,
    },
    {
      text: 'Special Price',
      value: 'specialPriceExclVat',
      align: 'end',
      width: 170,
    },
    {
      text: 'Total',
      value: 'totalNetAmount',
      align: 'end',
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ],
  other: [
    {
      text: 'Code',
      align: 'start',
      sortable: false,
      value: 'partNo',
      width: 130,
    },
    {
      text: 'Description',
      value: 'description',
    },
    {
      text: 'Qty',
      value: 'qty',
      align: 'center',
      width: 90,
    },
    {
      text: 'Retail Price',
      value: 'retailPriceExclVat',
      align: 'end',
      width: 170,
    },
    {
      text: 'Total',
      value: 'totalNetAmount',
      align: 'end',
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ],
};

export const PART_TYRE_POSITIONS = {
  BOTH: {
    value: 'B',
    text: 'Both',
  },
  FRONT: {
    value: 'F',
    text: 'Front',
  },
  BACK: {
    value: 'B',
    text: 'Back',
  },
};

export const MERCHANT_SERVICE_OFFERINGS = {
  MERCHANT_SERVICE_OFFERING_TYRES: 'tyres',
  MERCHANT_SERVICE_OFFERING_BRAKES: 'brakes',
  MERCHANT_SERVICE_OFFERING_BATTERIES: 'batteries',
  MERCHANT_SERVICE_OFFERING_SHOCKS: 'shocks',
  MERCHANT_SERVICE_OFFERING_GLASS: 'glass',
  MERCHANT_SERVICE_OFFERING_EXHAUSTS: 'exhausts',
};

export const AUTH_WORK_REQUIRED_ITEMS = {
  batteries: {
    key: 'batteries',
    value: 'Batteries',
    description: 'Add parts required for Batteries',
    default: false,
  },
  brakes: {
    key: 'brakes',
    value: 'Brakes',
    description: 'Add parts required for Brakes',
    default: false,
  },
  exhausts: {
    key: 'exhausts',
    value: 'exhausts',
    description: 'Add parts required for Exhausts',
    default: false,
  },
  glass: {
    key: 'glass',
    value: 'Glass',
    description: 'Add parts required for Glass',
    default: false,
  },
  other: {
    key: 'other',
    value: 'Other',
    description: 'Add other items here. IE: Wheel Alignment, Labour, etc',
    default: false,
  },
  shocks: {
    key: 'shocks',
    value: 'Shocks',
    description: 'Add parts required for Shocks',
    default: false,
  },
  tyres: {
    key: 'tyres',
    value: 'Tyres',
    description: 'Add parts required for Tyres',
    default: false,
  },
  default: {
    key: 'other',
    value: 'Other',
    description: 'Add other items here. IE: Wheel Alignment, Labour, etc',
    default: true,
  },
};

export const REFERENCE_LISTS = {
  MERCHANT_SERVICE_OFFERINGS: 'MERCHANT_SERVICE_OFFERINGS',
  INVOICE_DECLINE_REASONS: 'INVOICE_DECLINE_REASONS',
  FILENET_DOCUMENT_TYPES: 'FILENET_DOCUMENT_TYPES',
  AUTH_WORK_REQUIRED_ITEMS: 'AUTH_WORK_REQUIRED_ITEMS',
  FUNDALLOCATION_DECLINE_REASONS: 'FUNDALLOCATION_DECLINE_REASONS',
};

export const VEHICLE_CAPTURE_TABLE_HEADERS = [
  {
    text: 'Centre Number',
    value: 'centreNumber',
  },
  {
    text: 'Vehicle Make',
    value: 'vehicleMake',
  },
  {
    text: 'Vehicle Description',
    value: 'vehicleDescription',
  },
  {
    text: 'Vehicle Year',
    value: 'vehicleYear',
  },
  {
    text: 'Vehicle Registration',
    value: 'vehicleRegistration',
  },
  {
    text: 'Colour',
    value: 'colour',
  },
  {
    text: 'Transmission',
    value: 'transmission',
  },
  {
    text: 'New/Used',
    value: 'newOrUsed',
  },
];

export const VEHICLE_CAPTURE_SUBMITTED_TABLE_HEADERS = [

  {
    text: 'Centre Number',
    value: 'vehicleInformation.centreNumber',
    sortable: false,
  },
  {
    text: 'Vehicle Make',
    value: 'vehicleInformation.vehicleMake',
    sortable: false,
  },
  {
    text: 'Vehicle Description',
    value: 'vehicleInformation.vehicleDescription',
    sortable: false,
  },
  {
    text: 'Vehicle Year',
    value: 'vehicleInformation.vehicleYear',
    sortable: false,
  },
  {
    text: 'Vehicle Registration',
    value: 'vehicleInformation.registrationNumber',
    sortable: false,
  },
  {
    text: 'Date Added',
    value: 'vehicleInformation.addedDate',
    sortable: false,
  },

  {
    text: 'Live Date & Time',
    value: 'vehicleInformation.liveDateTime',
    sortable: false,
  },
  {
    text: 'Status',
    value: 'vehicleInformation.status',
    sortable: false,
  },
];

export const VEHICLE_CAPTURE_STATUSES = {
  VEHICLE_CAPTURE_STATUS_ALL: {
    value: 'ALL',
    text: 'All',
  },
  VEHICLE_CAPTURE_COMPLETE: {
    value: 'COMPLETE',
    text: 'Complete',
  },
  VEHICLE_CAPTURE_IN_PROGRESS: {
    value: 'IN_PROGRESS',
    text: 'In-Progress',
  },
  VEHICLE_CAPTURE_SAVED: {
    value: 'SAVED',
    text: 'Saved',
  },
  VEHICLE_CAPTURE_FAILED: {
    value: 'VALIDATION_FAILED',
    text: 'Validation Failed',
  },
};

export const REMITTANCE_TABLE_HEADERS = [
  {
    text: 'Originating Merchant',
    align: 'start',
    value: 'originatingMerchantId',
  },
  {
    text: 'Job Number',
    value: 'jobNumber',
  },
  {
    text: 'Dealer Reference',
    value: 'dealerReferenceNumber',
  },
  {
    text: 'Registration Number',
    value: 'registrationNumber',
  },
  {
    text: 'Authorisation Number',
    value: 'authorisationNumber',
  },
  {
    text: 'Authorisation Date',
    value: 'authorisationDate',
  },
  {
    text: 'Charge Back Reason',
    value: 'chargebackReason',
  },
  {
    text: 'Authorisation Amount',
    value: 'authorisationAmount',
    align: 'end',
  },
  {
    text: 'VAT Amount',
    value: 'vatAmount',
    align: 'end',
  },
  {
    text: 'Commission Amount',
    value: 'commission',
    align: 'end',
  },
  {
    text: 'Payment Amount',
    value: 'paymentAmount',
    align: 'end',
  },
];

export const CLIENTS_COMMISSIONS_PERCENT_TABLE_HEADERS = [
  {
    text: 'Previous',
  },
  {
    text: 'Current',
  },
  {
    text: 'Future',
  },
];

export const CLIENTS_COMMISSIONS_TABLE_HEADERS = [
  {
    text: 'Fleet Client Number',
    align: 'start',
    value: 'fleetClientNumber',
  },
  {
    text: 'Fleet Client Name',
    value: 'fleetClientName',
  },
  {
    text: 'Previous JV Settlement Discount',
    align: 'start',
    value: 'jvSettlementDiscount.previous.percent',
  },
  {
    text: 'Effective Date',
    value: 'jvSettlementDiscount.previous.effectiveDate',
  },
  {
    text: 'Current JV Settlement Discount',
    align: 'start',
    value: 'jvSettlementDiscount.current.percent',
  },
  {
    text: 'Effective Date',
    value: 'jvSettlementDiscount.current.effectiveDate',
  },
  {
    text: 'Future JV Settlement Discount',
    align: 'start',
    value: 'jvSettlementDiscount.future.percent',
  },
  {
    text: 'Effective Date',
    value: 'jvSettlementDiscount.future.effectiveDate',
  },
  {
    text: 'Annual Turnover',
    value: 'annualTurnover',
    align: 'end',
  },
  {
    text: 'Number Of Vehicles',
    value: 'numberOfVehicles',
    align: 'end',
  },
];
export const FUND_ALLOCATION_TABLE_HEADERS = [
  {
    text: 'Duplicate Entry',
    sortable: false,
    isFlaged: true,
    width: '2000px',
    align: 'center',
  },
  {
    text: 'Journal Entity Type',
    align: 'left',
    sortable: true,
    value: 'journalEntityType.value',
    nowrap: true,
  },
  {
    text: 'Type Of Journal',
    value: 'fundJournal.typeOfJournal.value',
    align: 'center',
    nowrap: true,
  },
  {
    text: 'Type Of Transfer',
    value: 'fundJournal.typeOfTransfer.value',
    align: 'centre',
    nowrap: true,
  },
  {
    text: 'From Client Number',
    value: 'fundJournal.from.clientNumber',
    align: 'center',
  },
  {
    text: 'From Billing Centre',
    value: 'fundJournal.from.billingCentre',
    align: 'center',
  },
  {
    text: 'From Fund Number',
    value: 'fundJournal.from.fundNumber',
    align: 'center',
  },
  {
    text: 'From Vehicle Number',
    value: 'fundJournal.from.vehicleNumber',
    align: 'center',
  },
  {
    text: 'To Client Number',
    value: 'fundJournal.to.clientNumber',
    align: 'center',
  },
  {
    text: 'To Billing Centre',
    value: 'fundJournal.to.billingCentre',
    align: 'center',
  },
  {
    text: 'To Fund Number',
    value: 'fundJournal.to.fundNumber',
    align: 'center',
  },
  {
    text: 'To Vehicle Number',
    value: 'fundJournal.to.vehicleNumber',
    align: 'center',
  },
  {
    text: 'Narration',
    value: 'narration',
    align: 'center',
  },
  {
    text: 'Ref/Reg',
    value: 'refReg',
    align: 'right',
  },
  {
    text: 'Date',
    value: 'journalDate',
    align: 'center',
    dataType: 'Date',
    sortable: true,
  },
  {
    text: 'Transfer Amount',
    value: 'clientJournal.transferAmount',
    align: 'right',
    dataType: 'Currency',
  },
  {
    text: 'Income Amount',
    value: 'fundJournal.incomeAmount',
    align: 'right',
    dataType: 'Currency',
  },
  {
    text: 'Expenses Amount',
    value: 'fundJournal.expensesAmount',
    align: 'right',
    dataType: 'Currency',
  },
  {
    text: 'Interest Amount',
    value: 'fundJournal.interestAmount',
    align: 'right',
    dataType: 'Currency',
  },
  {
    text: 'Transaction Code',
    value: 'clientJournal.transactionCode',
    align: 'right',
  },
  {
    text: 'VAT Indicator',
    value: 'clientJournal.vatIndicator',
    align: 'right',
  },
  {
    text: 'Batch Number',
    value: 'batchNumber',
    align: 'right',
  },
];

export const APPROVAL_STATUSES = {
  APPROVED: {
    value: 'APPROVED',
    text: 'Approved',
  },
  DECLINED: {
    value: 'DECLINED',
    text: 'Declined',
  },
};

export const USER_ROLES = {
  callCentre: {
    groups: ['MyBlueFleet - MMU Call Centre Manager', 'MyBlueFleet - MMU Call Centre Team Leader', 'MyBlueFleet - MMU Call Centre Team Member'],
    roles: {
      'MyBlueFleet - MMU Call Centre Manager': 'MyBlueFleet - MMU Call Centre Manager',
      'MyBlueFleet - MMU Call Centre Team Leader': 'MyBlueFleet - MMU Call Centre Team Leader',
      'MyBlueFleet - MMU Call Centre Team Member': 'MyBlueFleet - MMU Call Centre Team Member',
    },
  },
  compliance: {
    groups: ['MyBlueFleet - MMU Compliance Manager', 'MyBlueFleet - MMU Compliance Team Leader', 'MyBlueFleet - MMU Compliance Team Member'],
    roles: {
      'MyBlueFleet - MMU Compliance Manager': 'MyBlueFleet - MMU Compliance Manager',
      'MyBlueFleet - MMU Compliance Team Leader': 'MyBlueFleet - MMU Compliance Team Leader',
      'MyBlueFleet - MMU Compliance Team Member': 'MyBlueFleet - MMU Compliance Team Member',
    },
  },
  merchantInternal: {
    groups: ['MyBlueFleet - MMU Merchant Manager', 'MyBlueFleet - MMU Merchant Team Leader', 'MyBlueFleet - MMU Merchant Team Member'],
    roles: {
      'MyBlueFleet - MMU Merchant Manager': 'MyBlueFleet - MMU Merchant Manager',
      'MyBlueFleet - MMU Merchant Team Leader': 'MyBlueFleet - MMU Merchant Team Leader',
      'MyBlueFleet - MMU Merchant Team Member': 'MyBlueFleet - MMU Merchant Team Member',
    },
  },
  payments: {
    groups: ['MyBlueFleet - MMU Payments Manager', 'MyBlueFleet - MMU Payments Team Leader', 'MyBlueFleet - MMU Payments Team Member'],
    roles: {
      'MyBlueFleet - MMU Payments Manager': 'MyBlueFleet - MMU Payments Manager',
      'MyBlueFleet - MMU Payments Team Leader': 'MyBlueFleet - MMU Payments Team Leader',
      'MyBlueFleet - MMU Payments Team Member': 'MyBlueFleet - MMU Payments Team Member',
    },
  },

  merchantExternal: {
    groups: ['MyBlueFleet - Merchant Superuser', 'MyBlueFleet - Merchant Manager', 'MyBlueFleet - Merchant Team Member'],
    roles: {
      'MyBlueFleet - Merchant Superuser': 'MyBlueFleet - Merchant Superuser',
      'MyBlueFleet - Merchant Manager': 'MyBlueFleet - Merchant Manager',
      'MyBlueFleet - Merchant Team Member': 'MyBlueFleet - Merchant Team Member',
    },
  },
  customer: {
    groups: ['MyBlueFleet - Customer Superuser', 'MyBlueFleet - Customer Manager', 'MyBlueFleet - Customer Team Member'],
    roles: {
      'MyBlueFleet - Customer Superuser': 'MyBlueFleet - Customer Superuser',
      'MyBlueFleet - Customer Manager': 'MyBlueFleet - Customer Manager',
      'MyBlueFleet - Customer Team Member': 'MyBlueFleet - Customer Team Member',
    },
  },

  cardOperations: {
    groups: ['MyBlueFleet - Card Operations'],
    roles: {
      'MyBlueFleet - MMU Operations Team Member': 'MyBlueFleet - MMU Operations Team Member',
    },
  },

  fundOperations: {
    groups: ['MyBlueFleet - Fund Operations Team Leader', 'MyBlueFleet - Fund Operations Team Member'],
    roles: {
      'MyBlueFleet - Fund Operations Team Leader': 'MyBlueFleet - Fund Operations Team Leader',
      'MyBlueFleet - Fund Operations Team Member': 'MyBlueFleet - Fund Operations Team Member',
    },
  },
};

export const SORT_ORDER = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const EVENT_TYPES = {
  TASK: 'TASK',
  SREQ: 'SREQ',
};

export const LOGIN_NAMES = {
  merchant: 'MerchantLogin',
  staff: 'StaffLogin',
  customer: 'CustomerLogin',
};
export const JOURNAL_ENTITY_TYPE = {
  TYPE_C: { key: 'C', value: 'CLIENT' },
  TYPE_F: { key: 'F', value: 'FUND' },
};

export const JOURNAL_TYPE_OF_TRANSFER = {
  VEHICLE: { key: '1', value: 'Vehicle to vehicle' },
  FUNDCUSTOMER: { key: '2', value: 'Between a fund and a customer' },
};

export const TYPE_OF_JOURNAL = { key: 'TR', value: 'Transfer' };

export const VAT_INDICATORS = [
  { key: 'V', value: 'CHARGE VAT ON AMOUNT' },
  { key: 'N', value: 'DO NOT CHARGE VAT' },
  { key: 'F', value: 'AMOUNT INCLUDES VAT' },
  { key: 'E', value: ' EXEMPT FROM VAT' },
  { key: 'Z', value: 'ZERO VAT' },
];

export const TABLE_ACTIONS = [
  { icon: 'mdi-pencil', value: 'Edit' },
  { icon: 'mdi-delete', value: 'Remove' },
  {
    icon: 'mdi-content-duplicate',
    value: 'Acknowledge Duplicate',
    disabled: true,
  },
];

export const FUND_ALLOCATION_DOC_TYPE = {
  PROOFOFBANKACCOUNT: 'DOC_TYPE_FUND_ALLOCATION_PROOF_OF_BANK_ACCOUNT',
  OEMREQUEST: 'DOC_TYPE_FUND_ALLOCATION_OEM_REQUEST',
  AUTHORISATIONLETTER: 'DOC_TYPE_FUND_ALLOCATION_AUTHORISATION_LETTER',
  BOLPAYOUTREQUEST: 'DOC_TYPE_FUND_ALLOCATION_BOL_PAY_OUT_REQUEST',
  PROOFOFPAYMENT: 'DOC_TYPE_FUND_ALLOCATION_PROOF_OF_PAYMENT',
};

export const DOC_TYPE = {
  DOC_TYPE_FUND_ALLOCATION_OEM_REQUEST: 'OEM REQUEST',
  PROOFOFPAYMENT: 'PROOF OF PAYMENT',
};

export const DOC_TYPES = {
  Customer_Rejection: 'DOC_TYPE_CUSTOMER_REJECTION',
  Customer_Approval: 'DOC_TYPE_CUSTOMER_APPROVAL',
};

export const JOURNAL_TRANSACTION_CODE = ['027', '028', '180', '195'];

export const EXLUDED_SERVICES = ['brakes', 'shocks', 'exhausts'];

export const CUSTOMER_ACCOUNT_TYPES = {
  CREDIT: { key: 'N', value: 'Credit' },
  PREPAID: { key: 'Y', value: 'Pre-paid' },
};

export const CUSTOMER_CAPTURE_TYPES = {
  MANUAL: { key: '1', value: 'Manual' },
  TEMPLATE: { key: '2', value: 'Template' },
};

export const VEHICLE_UPLOAD_REQUESTTYPES = [
  { type: 'Add new vehicle/vehicles', disabled: false },
  { type: 'Cancel an existing vehicle', disabled: true },
  { type: 'Edit an existing vehicle', disabled: true },
  { type: 'Transfer a vehicle', disabled: true },
  { type: 'Edit responsible person', disabled: true },
];
export const CUSTOMER_CARD_REQUIREMENTS = {
  YES: { key: 'Y', value: 'Yes' },
  NO: { key: 'N', value: 'No' },
};

export const CUSTOMER_TRUCK_FUEL_NET = {
  YES: { key: 'Y', value: 'Yes' },
  NO: { key: 'N', value: 'No' },
};

export const OIL_COMPANY_CARD = {
  YES: { key: 'Y', value: 'Yes' },
  NO: { key: 'N', value: 'No' },
};

export const BD_FLEETMOVE_RFID = {
  YES: { key: 'Y', value: 'Yes' },
  NO: { key: 'N', value: 'No' },
};
export const BD_FLEETMOVE_RFID_NAMIBIA = {
  YES: { key: 'Y', value: 'Yes' },
  NO: { key: 'N', value: 'No' },
};
export const DRIVER_TO_PAY_IN_HOUSE = {
  YES: { key: 'Y', value: 'Yes' },
  NO: { key: 'N', value: 'No' },
};

export const BUDGET_OPTION = {
  YES: { key: 'Y', value: 'Yes' },
  NO: { key: 'N', value: 'No' },
};
export const DUAL_CLASS = {
  YES: { key: 'Y', value: 'Yes' },
  NO: { key: 'N', value: 'No' },
};
export const FRAUD_ODO = {
  YES: { key: 'Y', value: 'Yes' },
  NO: { key: 'N', value: 'No' },
};
export const DRIVER_ALLOW = {
  YES: { key: 'Y', value: 'Yes' },
  NO: { key: 'N', value: 'No' },
};
export const DRIVE_BILLING = {
  YES: { key: 'Y', value: 'Yes' },
  NO: { key: 'N', value: 'No' },
};
export const TRUCK_FUEL_NET = {
  YES: { key: 'Y', value: 'Yes' },
  NO: { key: 'N', value: 'No' },
};

export const CLIENT_SUBSIDY = {
  YES: { key: 'Y', value: 'Yes' },
  NO: { key: 'N', value: 'No' },
};

export const IGNORE_FIXED_COST = {
  YES: { key: 'Y', value: 'Yes' },
  NO: { key: 'N', value: 'No' },
};
export const IGNORE_ODOMETER = {
  YES: { key: 'Y', value: 'Yes' },
  NO: { key: 'N', value: 'No' },
};

export const OIL_SUBSIDY = {
  YES: { key: 'Y', value: 'Yes' },
  NO: { key: 'N', value: 'No' },
};

export const SMASH_GRAB = {

  YES: { key: 'Y', value: 'Yes' },

  NO: { key: 'N', value: 'No' },

};

export const WALLET_REQUIRED = {
  YES: { key: 'Y', value: 'Yes' },
  NO: { key: 'N', value: 'No' },
};

export const REGISTER_FOR_ORT = {
  YES: { key: 'Y', value: 'Yes' },
  NO: { key: 'N', value: 'No' },
};

export const TRANSACTION_LIMIT = {
  YES: { key: 'Y', value: 'Yes' },
  NO: { key: 'N', value: 'No' },
};

export const MERCHANT_LIMIT = {
  YES: { key: 'Y', value: 'Yes' },
  NO: { key: 'N', value: 'No' },
};

export const SYSTEM_DEAL = {
  YES: { key: 'Y', value: 'Yes' },
  NO: { key: 'N', value: 'No' },
};

export const ORT_ALLOWED = {
  YES: { key: 'Y', value: 'Yes' },
  NO: { key: 'N', value: 'No' },
};

export const ADDRESS_CAPTURE_TABLE_HEADERS = [
  {
    text: 'Street number',
    value: 'streetNumber',

  },
  {
    text: 'Street name',
    value: 'streetName',
  },
  {
    text: 'City/Town',
    value: 'town',
  },
  {
    text: 'Postal code',
    value: 'postalCode',
  },
  {
    text: 'Contact number',
    value: 'contactNumber',
  },
  {
    text: 'Contact name',
    value: 'contactName',
  },
  {
    text: 'Email address',
    value: 'emailAddress',
  },
];
