<template>
  <v-row dense
         :class="{'is--required': isRequired}">
    <v-col v-if="hasLabel"
           cols="12">
      <span class="text-h5 text-uppercase"
            :class="{'error--text': error, 'negative-number': showRedText}">{{ label }}</span>
    </v-col>
    <v-col cols="12">
      <slot>
        <span>{{ value }}</span>
      </slot>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number, Boolean],
      default: '',
    },
    noInput: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    showRedText: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      parentRules: [],
    };
  },
  computed: {
    hasLabel() {
      return !!(this.label && this.label.length > 0);
    },
    isRequired() {
      if (typeof this.parentRules === 'object') {
        return this.parentRules ? 'required' in this.parentRules : false;
      }
      return this.parentRules ? this.parentRules.includes('required') : false;
    },
  },
  mounted() {
    if (this.$parent.rules) {
      this.parentRules = this.$parent.rules;
    }
  },
};
</script>
