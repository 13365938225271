<template>
  <div>
    <div class="text-h4">
      {{ title }}
    </div>
    <div class="title-gradient" />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>
