import Vue from 'vue';
import VueWait from 'vue-wait';
import VueCurrencyInput from 'vue-currency-input';
import VueCurrencyFilter from 'vue-currency-filter';
import ErrorEvent from '@/models/ErrorEvent';
import VueHighlightJS from 'vue-highlightjs';
import VueTimepicker from 'vue2-timepicker';
import * as Bowser from 'bowser';
import App from './App';
import './vee-validate';
import plugins from './plugins/custom';
import './plugins/base';
import filters from './filters';
import router from './router';
import store from './store';
import './registerServiceWorker';
import vuetify from './plugins/vuetify';
import './scss/main.scss';
import '@mdi/font/css/materialdesignicons.css';

const browser = Bowser.getParser(window.navigator.userAgent);

Vue.component('VueTimepicker', VueTimepicker);
Vue.use(filters);
Vue.use(plugins);
Vue.use(VueWait);
Vue.use(VueHighlightJS);
Vue.use(VueCurrencyFilter,
  {
    symbol: 'R',
    thousandsSeparator: ',',
    fractionCount: 2,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: true,
  });
Vue.use(VueCurrencyInput, {
  globalOptions: {
    currency: {
      prefix: 'R ',
    },
    precision: 2,
    distractionFree: false,
    autoDecimalMode: true,
    valueRange: {
      min: 0,
    },
  },
});

Vue.config.productionTip = false;
Vue.config.errorHandler = (err, vm, info) => {
  // err: error trace
  // vm: component in which error occured
  // info: Vue specific error information such as lifecycle hooks, events etc.
  const errorEvent = new ErrorEvent();
  errorEvent.sendError(
    err,
    'Unhandled',
    {
      componentId: vm.id,
      info,
    },
  );

  // eslint-disable-next-line no-console
  console.error(err, vm);
};

window.onerror = function errhandle(msg, url, line, col, error) {
  const errorEvent = new ErrorEvent();
  errorEvent.sendError(
    error,
    'Unhandled',
    {
      msg,
      url,
      line,
      col,
      error,
    },
  );
};

window.addEventListener('unhandledrejection', (event) => {
  const errorEvent = new ErrorEvent();
  errorEvent.sendError(
    event.reason,
    'Unhandled',
    event.reason,
  );
});

Vue.mixin({
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },

    authUser: {
      get() {
        return this.$store.getters['session/user'];
      },
    },

    environment() {
      return process.env.VUE_APP_ENV;
    },

    isDevelopment() {
      return this.environment === 'Development' || this.environment === 'Testing';
    },

    isValidBrowser() {
      return browser.satisfies({
        // declare browsers per OS
        windows: {
          chrome: '>68',
          firefox: '>31',
          edge: '>89',
        },
        macos: {
          safari: '>10.1',
          chrome: '>68',
          firefox: '>31',
          edge: '>89',
        },
        // mobile: {
        //   safari: '>=9',
        //   'android browser': '>3.10',
        //   chrome: '>68',
        // },
      });
    },

  },
});

new Vue({
  router,
  store,
  vuetify,
  VueTimepicker,
  wait: new VueWait({
    useVuex: true,
    vuexModuleName: 'loading',
  }),
  render: (h) => h(App),
}).$mount('#app');
