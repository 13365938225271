<template>
  <ValidationProvider v-slot="{errors, classes}"
                      :name="label"
                      :rules="rules">
    <sb-input :label="label"
              :error="classes.invalid">
      <v-text-field ref="numberField"
                    v-model="innerValue"
                    :error-messages="errors"
                    outlined
                    dense
                    :percent="percent"
                    v-bind="$attrs"
                    :class="percent ? 'v-percent__slot' : ''"
                    v-on="$listeners"
                    @input="onInput"
                    @keydown="keyDown">
        <span v-if="percent"
              slot="append"
              class="v-input py-1">
          %
        </span>
      </v-text-field>
    </sb-input>
  </ValidationProvider>
</template>
<script>
import inputBase from '@/mixins/inputBase';

export default {
  mixins: [inputBase],

  props: {
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: undefined,
    },
    step: {
      type: Number,
      default: 1,
    },
    float: {
      type: Boolean,
      default: false,
    },
    percent: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: Number,
      default: 4,
    },
  },

  data() {
    return {
      innerValue: 0,
      interval: 0,
      timeout: 0,
      oldValue: 0,
    };
  },

  watch: {
    // Handles Internal Model Changes.
    innerValue(newVal) {
      const val = newVal || '0';
      this.$emit('input', val);
    },

    min(val) {
      this.innerValue = this.innerValue < val ? this.min : val;
    },

    max(val) {
      this.innerValue = this.innerValue > val ? this.max : val;
    },
  },

  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },

  mounted() {
    this.emitChange();

    // Check initial values
    this.innerValue = this.innerValue > this.max ? this.max : this.innerValue;
    this.innerValue = this.innerValue < this.min ? this.min : this.innerValue;
  },

  methods: {
    evaluateInnerValue(newVal) {
      if (newVal.toString().length > 0 && newVal !== this.oldValue) {
        // Check for "-0"
        this.innerValue = newVal === '-0' ? 0 : newVal;

        this.emitChange();
      }
    },

    emitChange() {
      this.oldValue = this.innerValue;
      this.$emit('input', this.innerValue);
    },

    onInput(val) {
      const value = val || '0';
      // Check if the min and max is valid, else set the innerValue to either
      // that max or min depending
      if (value && value !== '-') {
        const numValue = this.float ? parseFloat(value) : parseInt(value, 10);
        const isGtMax = numValue > this.max;
        const isLtMin = numValue < this.min;
        const valid = !isGtMax && !isLtMin;

        if (valid) {
          this.innerValue = numValue;
          this.oldValue = numValue;
        } else {
          this.$nextTick(() => {
            if (isGtMax) {
              this.innerValue = this.max;
            }

            if (isLtMin) {
              this.innerValue = this.min;
            }
          });
        }
      }
    },

    keyDown(e) {
      // Check and only allow certain numeric chars

      // Up key: Increase the value
      if (e.keyCode === 38) {
        this.increment();
        e.preventDefault();
        return;
      }
      // Down key: Decrease the value
      if (e.keyCode === 40) {
        this.decrement();
        e.preventDefault();
        return;
      }
      // Allow dot key for decimals:
      if (e.keyCode === 110 || e.keyCode === 190) {
        if (!this.float) {
          e.preventDefault();
          return;
        }
      }
      // Allow these keys only:
      if (
        // backspace, delete, tab, escape, enter, minus, dot
        [46, 8, 9, 27, 13, 110, 189, 190].indexOf(e.keyCode) >= 0 ||
        // Ctrl/cmd+A
        (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
        // Ctrl/cmd+C
        (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
        // Ctrl/cmd+R
        (e.keyCode === 82 && (e.ctrlKey || e.metaKey)) ||
        // Ctrl/cmd+X
        (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
        // home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)
      ) {
        return;
      }

      if ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
        e.preventDefault();
      }
    },
  },
};
</script>
