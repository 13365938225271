<template>
  <v-container class="sb-max px-0">
    <div v-if="title"
         class="text-h2 my-5">
      {{ title }}
    </div>

    <slot />
  </v-container>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Page Title',
    },
  },
};
</script>
