<template>
  <ValidationProvider v-slot="{errors}"
                      :name="fieldName || label"
                      :rules="rules">
    <v-radio v-model="innerValue"
             :label="label"
             :color="color"
             :error-messages="errors"
             :required="required"
             :class="customClass"
             on-icon="mdi-radiobox-marked"
             v-bind="$attrs"
             v-on="$listeners">
      <slot v-for="(_, name) in $slots"
            :slot="name"
            :name="name" />
      <template v-for="(_, name) in $scopedSlots"
                :slot="name"
                slot-scope="slotData">
        <slot :name="name"
              v-bind="slotData" />
      </template>
    </v-radio>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import inputBase from '@/mixins/inputBase';

export default {
  components: {
    ValidationProvider,
  },

  mixins: [inputBase],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    fieldName: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: 'secondary',
    },
    required: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: null,
    },
  },

  watch: {
    innerValue(value) {
      const data = {
        label: this.label,
        value,
      };
      this.$emit('input-with-label', data);
    },
  },
};
</script>
