<template>
  <ValidationProvider v-slot="{errors, classes}"
                      :name="label"
                      :rules="rules">
    <sb-input :label="label"
              :error="classes.invalid">
      <vue-timepicker :ref="refName"
                      v-model="innerValue"
                      :hour-range="[[minHour, maxHour]]"
                      :minute-range="[[minMinute, maxMinute]]"
                      :input-class="{'invalid': errors.length > 0}"
                      format="HH:mm"
                      close-on-complete
                      input-width="100%"
                      v-bind="$attrs"
                      v-on="$listeners"
                      @change="changeHandler">
        <template #clearButton>
          <v-icon>mdi-close</v-icon>
        </template>
      </vue-timepicker>
      <span class="field-details"
            :class="{'error--text': errors.length > 0}">{{ errors[0] }}</span>
    </sb-input>
  </validationprovider>
</template>

<script>
import inputBase from '@/mixins/inputBase';

export default {
  mixins: [inputBase],

  props: {
    max: {
      type: String,
      default: undefined,
    },
    min: {
      type: String,
      default: undefined,
    },
    refName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      time: '',
    };
  },

  computed: {
    minHour() {
      return this.min ? this.min.split(':').map(Number)[0] : 0;
    },

    maxHour() {
      return this.max ? this.max.split(':').map(Number)[0] : 23;
    },

    minMinute() {
      const minDefault = 0;
      if (this.min) {
        const result = this.min.split(':').map(Number)[1];
        if (this.time) {
          if (this.time.split(':').map(Number)[0] === this.min.split(':').map(Number)[0]) {
            return result;
          }
          return minDefault;
        }
        return result;
      }
      return minDefault;
    },

    maxMinute() {
      const maxDefault = 59;
      if (this.max) {
        const result = this.max.split(':').map(Number)[1];
        if (this.time) {
          if (this.time.split(':').map(Number)[0] === this.max.split(':').map(Number)[0]) {
            return result;
          }
          return maxDefault;
        }
        return result;
      }
      return maxDefault;
    },
  },

  watch: {
    innerValue(value) {
      if (!value) {
        this.$refs[this.refName].clearTime();

        // Also call the clearTime Extended function
        this.clearTimeEx();
      }

      const data = {
        label: Object.keys(this.$refs)[0],
        value,
      };
      this.$emit('input-with-label', data);
    },
  },

  methods: {
    changeHandler(eventData) {
      this.time = eventData.displayTime;
    },

    clearTimeEx() {
      this.$refs[this.refName].hour = '';
      this.$refs[this.refName].minute = '';
      this.$refs[this.refName].second = '';
      this.$refs[this.refName].apm = '';
    },
  },
};
</script>

<style>
  .field-details {
    word-break: break-word;
    overflow-wrap: break-word;
    line-height: 12px;
    font-size: 12px;
  }
  .vue__time-picker {
    display: inline-block;
    position: relative;
    font-size: 16px;
    width: 10em;
    font-family: 'Benton Sans', 'Roboto', sans-serif;
    vertical-align: middle;
    color: rgba(0, 0, 0, 0.87);
  }
  .vue__time-picker * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .vue__time-picker :focus {
    border-radius: 2px !important;
    border-color: #0033a1 !important;
    border-width: 2px !important;
    outline: none;
  }
  .vue__time-picker input.display-time {
    border: 1px solid #9e9e9e;
    width: 10em;
    height: 2.5em;
    padding: 0.3em 0.5em;
    font-size: 16px;
    border-radius: 2px;
  }
  .vue__time-picker input.display-time:hover {
    border-color: rgba(0, 0, 0, 0.86);
  }
  .vue__time-picker input.has-custom-icon {
    padding-left: 1.8em;
  }
  .vue__time-picker input.display-time.invalid:not(.skip-error-style) {
    border: 2px solid #c03;
    outline-color: #c03;
  }
  .vue__time-picker input.display-time:invalid {
    border: 2px solid #c03;
    outline-color: #c03;
  }
  .vue__time-picker input.display-time.disabled,
  .vue__time-picker input.display-time:disabled {
    color: rgba(0, 0, 0, 0.87);
  }
  .vue__time-picker .controls {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 3;
    -webkit-box-orient: horizontal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    pointer-events: none;
  }
  .vue__time-picker .controls,
  .vue__time-picker .controls > * {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-direction: normal;
  }
  .vue__time-picker .controls > * {
    cursor: pointer;
    width: auto;
    -webkit-box-orient: vertical;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 0.35em;
    color: rgba(0, 0, 0, 0.86);
    line-height: 100%;
    font-style: normal;
    pointer-events: auto;
    -webkit-transition: color 0.2s, opacity 0.2s;
    transition: color 0.2s, opacity 0.2s;
  }
  .vue__time-picker .controls > :hover {
    color: #797979;
  }
  .vue__time-picker .controls > :active,
  .vue__time-picker .controls > :focus {
    outline: 0;
  }
  .vue__time-picker .controls .char {
    font-size: 24px;
    line-height: 100%;
    -webkit-margin-before: -0.15em;
    color: #0033a1 !important;
  }
  .vue__time-picker .custom-icon {
    z-index: 2;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 1.8em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    pointer-events: none;
  }
  .vue__time-picker .controls img,
  .vue__time-picker .controls svg,
  .vue__time-picker .custom-icon img,
  .vue__time-picker .custom-icon svg {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    border: 0;
    outline: 0;
    max-width: 1em;
    height: auto;
  }
  .vue__time-picker .time-picker-overlay {
    z-index: 4;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .vue__time-picker .dropdown {
    position: absolute;
    z-index: 5;
    top: calc(2.2em + 8px);
    left: 0;
    background: #fff;
    -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
    width: 10em !important;
    height: 10em;
    font-weight: 400;
  }
  .vue__time-picker .dropdown.drop-up {
    top: auto;
    bottom: calc(2.2em + 1px);
  }
  .vue__time-picker .dropdown .select-list {
    width: 10em;
    height: 10em;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .vue__time-picker .dropdown .select-list:active,
  .vue__time-picker .dropdown .select-list:focus {
    outline: 0;
  }
  .vue__time-picker .dropdown ul {
    padding: 0;
    margin: 0;
    list-style: none;
    outline: 0;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0.00001px;
    flex: 1 1 0.00001px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .vue__time-picker .dropdown ul.apms,
  .vue__time-picker .dropdown ul.minutes,
  .vue__time-picker .dropdown ul.seconds {
    border-left: 1px solid #fff;
  }
  .vue__time-picker .dropdown ul li {
    list-style: none;
    text-align: center;
    padding: 0.3em 0;
    color: #161616;
  }
  .vue__time-picker .dropdown ul li:not(.hint):not([disabled]):focus,
  .vue__time-picker .dropdown ul li:not(.hint):not([disabled]):hover {
    background: rgba(0, 0, 0, 0.08);
    color: #0070e2;
    cursor: pointer;
  }
  .vue__time-picker .dropdown ul li:not([disabled]).active,
  .vue__time-picker .dropdown ul li:not([disabled]).active:focus,
  .vue__time-picker .dropdown ul li:not([disabled]).active:hover {
    background: #0070e2;
    color: #fff;
  }
  .vue__time-picker .dropdown ul li[disabled],
  .vue__time-picker .dropdown ul li[disabled]:hover {
    background: transparent;
    opacity: 0.3;
    cursor: not-allowed;
  }
  .vue__time-picker .dropdown .hint {
    color: #a5a5a5;
    cursor: default;
    font-size: 0.8em;
  }
  .vue__time-picker .dropdown ::-webkit-scrollbar {
    width: 10px;
  }
  .vue__time-picker .dropdown ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }
  .vue__time-picker .dropdown ::-webkit-scrollbar-corner {
    background-color: black;
  }
  .vue__time-picker .dropdown ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #0070e2;
  }
  .clear-btn.has-custom-btn {
    padding-right: 12px !important;
  }
</style>
