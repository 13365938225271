<template>
  <v-menu :ref="refName"
          v-model="displayPicker"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="innerValue"
          :disabled="$wait.any"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px">
    <template #activator="{on}">
      <sb-text-field v-model="dateRangeText"
                     clearable
                     readonly
                     sb-solo
                     flat
                     light
                     hide-details
                     prepend-inner-icon="mdi-calendar"
                     v-bind="$attrs"
                     v-on="on"
                     @click:clear="clearHandler" />
    </template>
    <v-date-picker v-if="displayPicker"
                   v-model="innerValue"
                   :max="max"
                   :min="min"
                   range
                   full-width
                   color="secondary"
                   format="24hr"
                   @change="changeHandler" />
  </v-menu>
</template>
<script>
import pickerBaseMixin from '@/mixins/pickerBase';
import { sortStringDateArray } from '@/utils';

export default {
  mixins: [pickerBaseMixin],

  props: {
    value: {
      type: Array,
      default: () => [],
    },
    sortDates: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      innerValue: [],
    };
  },

  computed: {
    dateRangeText: {
      get() {
        if (this.innerValue !== null && this.innerValue.length > 0) {
          if (this.innerValue.length > 1) {
            return this.innerValue.join(' to ');
          }

          return this.innerValue;
        }

        return '';
      },

      set(value) {
        if (value) {
          if (value.constructor.name === 'Array') {
            this.innerValue = value;
          } else {
            this.innerValue = value.split(' to ');
          }
        } else {
          this.innerValue = [];
        }
      },
    },
  },

  methods: {
    changeHandler(dateRangeData) {
      let dateRange = dateRangeData;

      // Sort the order of the date range if sort dates is set
      if (this.sortDates) {
        dateRange = sortStringDateArray(dateRange);
      }

      // Update the innerValue so the correct selected range text will display
      this.innerValue = dateRange;

      // Update the ref value so it can be accessed from ref
      this.$refs[this.refName].save(dateRange);

      // Fire the input event so the v-model is updated correctly
      this.$emit('input', dateRange);

      // Fire the change event so it bubbles up to the parent
      this.$emit('change', dateRange);
    },

    clearHandler() {
      this.innerValue = [];
      this.$emit('clear');
    },
  },
};
</script>
