<template>
  <ValidationProvider v-slot="{errors, classes}"
                      :name="label"
                      :rules="rules">
    <sb-input :label="label"
              :error="classes.invalid">
      <v-text-field v-model="innerValue"
                    v-currency="allowNegative ? negativeCurrency : currency"
                    :error-messages="errors"
                    outlined
                    single-line
                    dense
                    v-bind="$attrs"
                    v-on="$listeners"
                    @input="onInput"
                    @keydown="keyDown" />
    </sb-input>
  </ValidationProvider>
</template>

<script>
import inputBase from '@/mixins/inputBase';
import currencyMixin from '@/mixins/currency';

export default {
  mixins: [inputBase, currencyMixin],
  props: {
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: undefined,
    },
    float: {
      type: Boolean,
      default: false,
    },
    allowNegative: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      innerValue: 0,
      interval: 0,
      timeout: 0,
      oldValue: 0,
    };
  },
  computed: {
    parsedInnerValue() {
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(this.innerValue)) {
        return this.$ci.parse(this.innerValue);
      }
      return this.innerValue;
    },
    parsedValue() {
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(this.value)) {
        return this.$ci.parse(this.value);
      }
      return this.value;
    },
  },

  watch: {
    // Handles Internal Model Changes.
    innerValue(newVal) {
      const val = newVal || '0';

      if (val > this.max) {
        return;
      }

      this.$emit('input', val);
    },
  },

  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
  mounted() {
    this.emitChange();
  },
  methods: {
    keyDown(event) {
      if (event.key === 'Enter') {
        this.$emit('enter');
      }
    },

    emitChange() {
      this.oldValue = this.parsedInnerValue;
      this.$emit('input', this.parsedInnerValue);
    },

    onInput(val) {
      const numVal = this.$ci.parse(val);
      const value = numVal || '0';
      // Check if the min and max is valid, else set the innerValue to either
      // that max or min depending
      if (value && value !== '-') {
        const numValue = this.float ? parseFloat(value) : parseInt(value, 10);
        const isGtMax = numValue > this.max;
        const valid = !isGtMax;
        if (valid) {
          this.oldValue = val;
        } else {
          this.$nextTick(() => {
            if (isGtMax) {
              this.innerValue = this.oldValue;
            }
          });
        }
      }
    },
  },
};
</script>
