import { USER_ROLES } from './constants';

export default [
  {
    icon: 'mdi-home',
    text: 'Home',
    homePage: false,
    path: '/',
    roles: ['ALL'],
  },
  {
    icon: 'mdi-car-multiple',
    text: 'SLA Management',
    homePage: true,
    path: '/sla-management',
    expanded: true,
    roles: [
      USER_ROLES.callCentre.roles['MyBlueFleet - MMU Call Centre Manager'],
      USER_ROLES.callCentre.roles['MyBlueFleet - MMU Call Centre Team Leader'],
      USER_ROLES.callCentre.roles['MyBlueFleet - MMU Call Centre Team Member'],
      USER_ROLES.customer.roles['MyBlueFleet - Customer Superuser'],
      USER_ROLES.customer.roles['MyBlueFleet - Customer Manager'],
      USER_ROLES.customer.roles['MyBlueFleet - Customer Team Member'],
    ],
    children: [
      {
        icon: 'mdi-monitor-dashboard',
        text: 'Dashboard',
        homePage: true,
        path: '/sla-management/dashboard',
        roles: [
          USER_ROLES.callCentre.roles['MyBlueFleet - MMU Call Centre Manager'],
          USER_ROLES.callCentre.roles['MyBlueFleet - MMU Call Centre Team Leader'],
          USER_ROLES.callCentre.roles['MyBlueFleet - MMU Call Centre Team Member'],
        ],
      },
      {
        icon: 'mdi-plus',
        text: 'Create Service Request',
        homePage: true,
        path: '/sla-management/create-service-request',
        roles: [
          USER_ROLES.callCentre.roles['MyBlueFleet - MMU Call Centre Manager'],
          USER_ROLES.callCentre.roles['MyBlueFleet - MMU Call Centre Team Leader'],
          USER_ROLES.callCentre.roles['MyBlueFleet - MMU Call Centre Team Member'],
        ],
      },
      {
        icon: 'mdi-clock-start',
        text: 'Service Requests',
        homePage: true,
        path: '/sla-management/service-requests',
        roles: [
          USER_ROLES.callCentre.roles['MyBlueFleet - MMU Call Centre Manager'],
          USER_ROLES.callCentre.roles['MyBlueFleet - MMU Call Centre Team Leader'],
          USER_ROLES.callCentre.roles['MyBlueFleet - MMU Call Centre Team Member'],
          USER_ROLES.customer.roles['MyBlueFleet - Customer Superuser'],
          USER_ROLES.customer.roles['MyBlueFleet - Customer Manager'],
          USER_ROLES.customer.roles['MyBlueFleet - Customer Team Member'],
        ],
      },
    ],
  },
  {
    icon: 'mdi-shield-car',
    text: 'Electronic Authorisations',
    homePage: true,
    path: '/electronic-authorisations',
    expanded: true,
    roles: [
      USER_ROLES.callCentre.roles['MyBlueFleet - MMU Call Centre Manager'],
      USER_ROLES.callCentre.roles['MyBlueFleet - MMU Call Centre Team Leader'],
      USER_ROLES.callCentre.roles['MyBlueFleet - MMU Call Centre Team Member'],
      USER_ROLES.compliance.roles['MyBlueFleet - MMU Compliance Manager'],
      USER_ROLES.compliance.roles['MyBlueFleet - MMU Compliance Team Leader'],
      USER_ROLES.compliance.roles['MyBlueFleet - MMU Compliance Team Member'],
      USER_ROLES.merchantInternal.roles['MyBlueFleet - MMU Merchant Manager'],
      USER_ROLES.merchantInternal.roles['MyBlueFleet - MMU Merchant Team Leader'],
      USER_ROLES.merchantInternal.roles['MyBlueFleet - MMU Merchant Team Member'],
      USER_ROLES.payments.roles['MyBlueFleet - MMU Payments Manager'],
      USER_ROLES.payments.roles['MyBlueFleet - MMU Payments Team Leader'],
      USER_ROLES.payments.roles['MyBlueFleet - MMU Payments Team Member'],
      USER_ROLES.merchantExternal.roles['MyBlueFleet - Merchant Superuser'],
      USER_ROLES.merchantExternal.roles['MyBlueFleet - Merchant Manager'],
      USER_ROLES.merchantExternal.roles['MyBlueFleet - Merchant Team Member'],
      USER_ROLES.customer.roles['MyBlueFleet - Customer Superuser'],
      USER_ROLES.customer.roles['MyBlueFleet - Customer Manager'],
      USER_ROLES.customer.roles['MyBlueFleet - Customer Team Member'],
    ],
    children: [
      // {
      //   icon: 'mdi-monitor-dashboard',
      //   text: 'Dashboard',
      //   path: '/electronic-authorisations/dashboard',
      // },
      {
        icon: 'mdi-plus',
        text: 'Create Work Authorisation',
        homePage: true,
        path: '/electronic-authorisations/create-work-authorisation',
        roles: [
          USER_ROLES.callCentre.roles['MyBlueFleet - MMU Call Centre Manager'],
          USER_ROLES.callCentre.roles['MyBlueFleet - MMU Call Centre Team Leader'],
          USER_ROLES.callCentre.roles['MyBlueFleet - MMU Call Centre Team Member'],
          USER_ROLES.merchantExternal.roles['MyBlueFleet - Merchant Superuser'],
          USER_ROLES.merchantExternal.roles['MyBlueFleet - Merchant Manager'],
          USER_ROLES.merchantExternal.roles['MyBlueFleet - Merchant Team Member'],
        ],
      },
      {
        icon: 'mdi-tools',
        text: 'Work Authorisations',
        homePage: true,
        path: '/electronic-authorisations/work-authorisations',
        roles: [
          USER_ROLES.callCentre.roles['MyBlueFleet - MMU Call Centre Manager'],
          USER_ROLES.callCentre.roles['MyBlueFleet - MMU Call Centre Team Leader'],
          USER_ROLES.callCentre.roles['MyBlueFleet - MMU Call Centre Team Member'],
          USER_ROLES.compliance.roles['MyBlueFleet - MMU Compliance Manager'],
          USER_ROLES.compliance.roles['MyBlueFleet - MMU Compliance Team Leader'],
          USER_ROLES.compliance.roles['MyBlueFleet - MMU Compliance Team Member'],
          USER_ROLES.merchantInternal.roles['MyBlueFleet - MMU Merchant Manager'],
          USER_ROLES.merchantInternal.roles['MyBlueFleet - MMU Merchant Team Leader'],
          USER_ROLES.merchantInternal.roles['MyBlueFleet - MMU Merchant Team Member'],
          USER_ROLES.payments.roles['MyBlueFleet - MMU Payments Manager'],
          USER_ROLES.payments.roles['MyBlueFleet - MMU Payments Team Leader'],
          USER_ROLES.payments.roles['MyBlueFleet - MMU Payments Team Member'],
          USER_ROLES.merchantExternal.roles['MyBlueFleet - Merchant Superuser'],
          USER_ROLES.merchantExternal.roles['MyBlueFleet - Merchant Manager'],
          USER_ROLES.merchantExternal.roles['MyBlueFleet - Merchant Team Member'],
          USER_ROLES.customer.roles['MyBlueFleet - Customer Superuser'],
          USER_ROLES.customer.roles['MyBlueFleet - Customer Manager'],
          USER_ROLES.customer.roles['MyBlueFleet - Customer Team Member'],
        ],
      },
      {
        icon: 'mdi-credit-card-outline',
        text: 'Remittance Advice',
        homePage: true,
        path: '/electronic-authorisations/remittance-advice',
        roles: [
          USER_ROLES.callCentre.roles['MyBlueFleet - MMU Call Centre Manager'],
          USER_ROLES.callCentre.roles['MyBlueFleet - MMU Call Centre Team Leader'],
          USER_ROLES.callCentre.roles['MyBlueFleet - MMU Call Centre Team Member'],
          USER_ROLES.merchantInternal.roles['MyBlueFleet - MMU Merchant Manager'],
          USER_ROLES.merchantInternal.roles['MyBlueFleet - MMU Merchant Team Leader'],
          USER_ROLES.merchantInternal.roles['MyBlueFleet - MMU Merchant Team Member'],
          USER_ROLES.payments.roles['MyBlueFleet - MMU Payments Manager'],
          USER_ROLES.payments.roles['MyBlueFleet - MMU Payments Team Leader'],
          USER_ROLES.payments.roles['MyBlueFleet - MMU Payments Team Member'],
          USER_ROLES.merchantExternal.roles['MyBlueFleet - Merchant Superuser'],
          USER_ROLES.merchantExternal.roles['MyBlueFleet - Merchant Manager'],
          USER_ROLES.merchantExternal.roles['MyBlueFleet - Merchant Team Member'],
        ],
      },
    ],
  },
  {
    icon: 'mdi-piggy-bank-outline',
    text: 'Fund Management',
    homePage: true,
    path: '/fund-management',
    expanded: true,
    roles: [
      USER_ROLES.merchantInternal.roles['MyBlueFleet - MMU Merchant Manager'],
      USER_ROLES.merchantInternal.roles['MyBlueFleet - MMU Merchant Team Leader'],
      USER_ROLES.merchantInternal.roles['MyBlueFleet - MMU Merchant Team Member'],
    ],
    children: [
      {
        icon: 'mdi-calculator',
        text: 'Commission Charges',
        homePage: true,
        path: '/fund-management/commission-charges',
        roles: [
          USER_ROLES.merchantInternal.roles['MyBlueFleet - MMU Merchant Manager'],
          USER_ROLES.merchantInternal.roles['MyBlueFleet - MMU Merchant Team Leader'],
          USER_ROLES.merchantInternal.roles['MyBlueFleet - MMU Merchant Team Member'],
        ],
      },
    ],
  },
  /* new menu features still in development */

  {
    icon: 'mdi-cash-multiple',
    text: 'Fund Allocation',
    homePage: true,
    path: '',
    expanded: true,
    roles: [
      USER_ROLES.fundOperations.roles['MyBlueFleet - Fund Operations Team Leader'],
      USER_ROLES.fundOperations.roles['MyBlueFleet - Fund Operations Team Member'],
    ],
    children: [
      {
        icon: 'mdi-plus',
        text: 'Create Fund Allocation Request',
        homePage: true,
        path: '/fund-allocations/create-fund-allocation-request',
        roles: [
          USER_ROLES.fundOperations.roles['MyBlueFleet - Fund Operations Team Leader'],
          USER_ROLES.fundOperations.roles['MyBlueFleet - Fund Operations Team Member'],
        ],
      },
      {
        icon: 'mdi-cash-refund',
        text: 'Fund Allocations',
        homePage: true,
        path: '/fund-allocations/fund-allocations-inbox',
        roles: [
          USER_ROLES.fundOperations.roles['MyBlueFleet - Fund Operations Team Leader'],
          USER_ROLES.fundOperations.roles['MyBlueFleet - Fund Operations Team Member'],
        ],
      },
    ],
  },
  {
    icon: 'mdi-car-info',
    text: 'Vehicle Capturing',
    homePage: true,
    path: '/vehicle-capturing',
    expanded: true,
    roles: [
      USER_ROLES.merchantInternal.roles['MyBlueFleet - MMU Merchant Manager'],
      USER_ROLES.merchantInternal.roles['MyBlueFleet - MMU Merchant Team Leader'],
      USER_ROLES.merchantInternal.roles['MyBlueFleet - MMU Merchant Team Member'],
    ],
    children: [
      {
        icon: 'mdi-plus',
        text: 'Create Vehicle Request',
        homePage: true,
        path: '/vehicle-capturing/create-vehicle-request',
        roles: [
          USER_ROLES.cardOperations.roles['MyBlueFleet - MMU Operations Team Member'],
          USER_ROLES.fundOperations.roles['MyBlueFleet - Fund Operations Team Leader'],
          USER_ROLES.fundOperations.roles['MyBlueFleet - Fund Operations Team Member'],
        ],
      },
      {
        icon: 'mdi-car-multiple',
        text: 'Vehicle Capture Inbox',
        homePage: true,
        path: '/vehicle-capturing/vehicle-capturing-inbox',
        roles: [
          USER_ROLES.merchantInternal.roles['MyBlueFleet - MMU Merchant Manager'],
          USER_ROLES.merchantInternal.roles['MyBlueFleet - MMU Merchant Team Leader'],
          USER_ROLES.merchantInternal.roles['MyBlueFleet - MMU Merchant Team Member'],
        ],
      },
    ],
  },
];
