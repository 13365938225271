/* eslint-disable */
export const BULK_UPLOAD_VEHICLE_SCHEMA = {
  VehicleInformation: {
    prop: 'vehicleInformation',
    type: {
      Centre: {
        prop: 'centreNumber',
        type: String,
      },
      Registration: {
        prop: 'registerationNumber',
        type: String,
      },
      'Reg Date': {
        prop: 'vehicleRegistrationDate',
        type: String,
      },
      'Veh Group': {
        prop: 'vehicleGroup',
        type: String,
      },
      'Veh ID': {
        prop: 'vehicleId',
        type: String,
      },
      'Eff Date': {
        prop: 'vehicleEffectiveDate',
        type: String,
      },
      'Veh Type': {
        prop: 'vehicleType',
        type: String,
      },
      'Manufacturer': {
        prop: 'vehicleMake',
        type: String,
      },
      'Veh Year': {
        prop: 'vehicleYear',
        type: String,
      },
      Model: {
        prop: 'vehicleModel',
        type: String,
      },
      'Veh Description': {
        prop: 'vehicleModel',
        type: String,
      },
      'Veh Category': {
        prop: 'vehicleCategory',
        type: String,
      },
      'Model Extn': {
        prop: 'modelExtension',
        type: String,
      },
      Transmission: {
        prop: 'transmission',
        type: String,
      },
      'Fuel Type': {
        prop: 'fuelType',
        type: String,
      },
      Colour: {
        prop: 'colour',
        type: String,
      },
      'Service Unit': {
        prop: 'serviceUnit',
        type: String,
      },
      'Manufacture FuelTank Capacity': {
        prop: 'manufactureFuelTankCapacity',
        type: String,
      },
      'is Fuel Capacity Known': {
        prop: 'isCustomCapacityKnown',
        type: String,
      },
      'Custom Fuel Capacity': {
        prop: 'customFuelCapacity',
        type: String,
      },
      'is New Or Used': {
        prop: 'isNewOrUsed',
        type: String,
      },
      'Opening Odometer': {
        prop: 'openingOdometer',
        type: String,
      },
      'Start Odometer': {
        prop: 'startOdometer',
        type: String,
      },
      'MMU Opening Odometer': {
        prop: 'mmuOpeningOdometer',
        type: String,
      },
      'MMU Opening Odometer Date': {
        prop: 'mmuOpeningOdometerDate',
        type: String,
      },
      'Last Service': {
        prop: 'lastService',
        type: String,
      },
      'Selling Dealer': {
        prop: 'sellingDealer',
        type: String,
      },
      'Sales person': {
        prop: 'salesPerson',
        type: String,
      },
    },
  },
  VehicleSetup: {
    prop: 'vehicleSetup',
    type: {
      Usage: {
        prop: 'usage',
        type: String,
      },
      'SERV/MAIN PLAN TYPE': {
        prop: 'servmainPlainType',
        type: String,
      },
      'PLAN EXPIRY DATE': {
        prop: 'planExpiryDate',
        type: String,
      },
      'PLAN END KMS': {
        prop: 'planEndKms',
        type: String,
      },
      'Engine Number': {
        prop: 'engineNumber',
        type: String,
      },
      'VIN Number': {
        prop: 'vinNumber',
        type: String,
      },
      'Chassis Number': {
        prop: 'chassisNumber',
        type: String,
      },
      'Vehicle Register Number': {
        prop: 'registerNumber',
        type: String,
      },
      'MMU Contract Start': {
        prop: 'contractStartDate',
        type: String,
      },
      'MMU Territory': {
        prop: 'territory',
        type: String,
      },
      'Budget Date': {
        prop: 'budgetDate',
        type: String,
      },
      'Final Contract Km': {
        prop: 'finalContractKm',
        type: String,
      },
      'Contract Months': {
        prop: 'contractMonths',
        type: String,
      },
      'vehicle Application Code': {
        prop: 'vehicleApplicationCode',
        type: String,
      },
      'Tyres Imported (Y/N)': {
        prop: 'tyresImported',
        type: String,
      },
      'Front Tyre Size': {
        prop: 'frontTyreSize',
        type: String,
      },
      'Front QTY': {
        prop: 'frontTyreQuantity',
        type: String,
      },
      'Rear Tyre Size': {
        prop: 'rearTyreSize',
        type: String,
      },
      'Rear QTY': {
        prop: 'rearTyreQuantity',
        type: String,
      },
    },
  },
  ProductSetup: {
    prop: 'productSetup',
    type: {
      Product: {
        prop: 'product',
        type: String,
      },
      'Secondary Product': {
        prop: 'secondaryProduct',
        type: String,
      },
      'Ort Country': {
        prop: 'ortCountry',
        type: String,
      },
      OrtRegion: {
        prop: 'ortRegion',
        type: String,
      },
      'Ort Veh Class': {
        prop: 'ortVehicleClass',
        type: String,
      },
      'Dual Class': {
        prop: 'dualClass',
        type: String,
      },
      'Admin Group': {
        prop: 'adminFeeGroup',
        type: String,
      },
      'Authority Group': {
        prop: 'authorityGroup',
        type: String,
      },
      'National ContributionL': {
        prop: 'nationalContributionL',
        type: String,
      },
      'National Contribution Fuel CPK': {
        prop: 'nationalContributionFuelCPK',
        type: String,
      },
      'Maintenance Number': {
        prop: 'maintenanceNumber',
        type: String,
      },
      'Exception Group': {
        prop: 'exceptionGroup',
        type: String,
      },
      'ReplacementKilometer Group': {
        prop: 'replacementKilometerGroup',
        type: String,
      },
      'Replacement Month Group': {
        prop: 'replacementMonthGroup',
        type: String,
      },
      'system 4Deal Number': {
        prop: 'system4DealNumber',
        type: String,
      },
      'System4 Engine Number': {
        prop: 'system4EngineNumber',
        type: String,
      },
      'System4 Registration': {
        prop: 'system4Registration',
        type: String,
      },
      'Trans Limit': {
        prop: 'transLimit',
        type: String,
      },
      'Monthly Total Limit Amount': {
        prop: 'monthlyTotalLimitAmount',
        type: String,
      },
      'Month Litre Limit': {
        prop: 'monthLitreLimit',
        type: String,
      },
      'Month Fuel Amount': {
        prop: 'monthFuelAmount',
        type: String,
      },
      'Month RM Amount': {
        prop: 'monthRmAmount',
        type: String,
      },
      'Monthly Total Limit Amount': {
        prop: 'monthlyTotalLimitAmount',
        type: String,
      },
      'Merchant Limit': {
        prop: 'merchantLimit',
        type: String,
      },
      'V/A ACTION': {
        prop: 'vaAction',
        type: String,
      },
      'V/A PROD CODE': {
        prop: 'vaProductCode',
        type: String,
      },
      'V/A INSTAL FEE': {
        prop: 'vaInstalFee',
        type: String,
      },
      'V/A INSTAL MTHS': {
        prop: 'vaInstalMonths',
        type: String,
      },
      'V/A SERVICE FEE': {
        prop: 'vaServiceFee',
        type: String,
      },
      'V/A EFF DATE': {
        prop: 'vaEffectiveDate',
        type: String,
      },
      'V/A DRIVER ALLOW': {
        prop: 'vaDriverAllow',
        type: String,
      },
      'V/A DRIVER BILL': {
        prop: 'vaDriverBill',
        type: String,
      },
    },
  },
  DriverInformation: {
    prop: 'driverInformation',
    type: {
      'Driver Initials': {
        prop: 'driverInitials',
        type: String,
      },
      'Driver Name': {
        prop: 'driverName',
        type: String,
      },
      'Driver ID': {
        prop: 'driverID',
        type: String,
      },
      'Date Of Birth': {
        prop: 'dateOfBirth',
        type: String,
      },
      Passport: {
        prop: 'foreignPassportNumber',
        type: String,
      },
      'Passport Country': {
        prop: 'passportCountry',
        type: String,
      },
      'Driver Billing': {
        prop: 'driverBilling',
        type: String,
      },
      'Driver Allowance Type': {
        prop: 'driverAllowanceType',
        type: String,
      },
      Amount: {
        prop: 'amount',
        type: String,
      },
      'Payment Method': {
        prop: 'paymentMethod',
        type: String,
      },
      'ACB Domicile': {
        prop: 'acbDomicile',
        type: String,
      },
      Account: {
        prop: 'account',
        type: String,
      },
      'Account Type': {
        prop: 'accountType',
        type: String,
      },
      'Account Name': {
        prop: 'accountName',
        type: String,
      },
    },
  },
  MaintananceContractSetup: {
    prop: 'maintananceContractSetup',
    type: {
      'Contract Type': {
        prop: 'contractType',
        type: String,
      },
      'Billing Type': {
        prop: 'billingType',
        type: String,
      },
      'Sponsorship Amount': {
        prop: 'sponsorshipAmount',
        type: String,
      },
      'Actual Contract End Date': {
        prop: 'actualContractEndDate',
        type: String,
      },
      'Category  Code (N/Y)': {
        prop: 'categoryCode',
        type: String,
      },
      'Cat Code 1': {
        prop: 'categoryCode1',
        type: String,
      },
      'Cat Code 2': {
        prop: 'categoryCode2',
        type: String,
      },
      'Cat Code 3': {
        prop: 'categoryCode3',
        type: String,
      },
      'Cat Code 4': {
        prop: 'categoryCode4',
        type: String,
      },
      'Cat Code 5': {
        prop: 'categoryCode5',
        type: String,
      },
      'Category Limitation (Y/N)': {
        prop: 'categoryLimitation',
        type: String,
      },
      'Cat Limit Code 1': {
        prop: 'categoryLimitCode1',
        type: String,
      },
      'Cat Limit Code 2': {
        prop: 'categoryLimitCode2',
        type: String,
      },
      'Cat Limit Code 3': {
        prop: 'categoryLimitCode3',
        type: String,
      },
      'Cat Limit Code 4': {
        prop: 'categoryLimitCode4',
        type: String,
      },
      'Cat Limit Code 5': {
        prop: 'categoryLimitCode5',
        type: String,
      },
      'Front Allow 1': {
        prop: 'frontAllow1',
        type: String,
      },
      'Front Allow 2': {
        prop: 'frontAllow2',
        type: String,
      },
      'Front Allow 3': {
        prop: 'frontAllow3',
        type: String,
      },
      'Front Allow 4': {
        prop: 'frontAllow4',
        type: String,
      },
      'Front Allow 5': {
        prop: 'frontAllow5',
        type: String,
      },
      'Rear Allow 1': {
        prop: 'rearAllow1',
        type: String,
      },
      'Rear Allow 2': {
        prop: 'rearAllow2',
        type: String,
      },
      'Rear Allow 3': {
        prop: 'rearAllow3',
        type: String,
      },
      'Rear Allow 4': {
        prop: 'rearAllow4',
        type: String,
      },
      'Rear Allow 5': {
        prop: 'rearAllow5',
        type: String,
      },
      'Tag Allow 1': {
        prop: 'tagAllow1',
        type: String,
      },
      'Tag Allow 2': {
        prop: 'tagAllow2',
        type: String,
      },
      'Tag Allow 3': {
        prop: 'tagAllow3',
        type: String,
      },
      'Tag Allow 4': {
        prop: 'tagAllow4',
        type: String,
      },
      'Tag Allow 5': {
        prop: 'tagAllow5',
        type: String,
      },
      'Other Allow 1': {
        prop: 'otherAllow1',
        type: String,
      },
      'Other Allow 2': {
        prop: 'otherAllow2',
        type: String,
      },
      'Other Allow 3': {
        prop: 'otherAllow3',
        type: String,
      },
      'Other Allow 4': {
        prop: 'otherAllow4',
        type: String,
      },
      'Other Allow 5': {
        prop: 'otherAllow5',
        type: String,
      },
      Currency: {
        prop: 'currency',
        type: String,
      },
      'Currency Exchange Rate': {
        prop: 'currencyExchangeRate',
        type: String,
      },
      'Inflation Indicator': {
        prop: 'inflationIndicator',
        type: String,
      },
      'Model Job': {
        prop: 'modelJob',
        type: String,
      },
      'Standard Kilometer End': {
        prop: 'standardKilometerEnd',
        type: String,
      },
      'Extended Kilometer Start': {
        prop: 'extendedKilometerStart',
        type: String,
      },
      'Extended Kilometer End': {
        prop: 'extendedKilometerEnd',
        type: String,
      },
      'CPK Rate Applies': {
        prop: 'cpkRateApplies',
        type: String,
      },
      'CPK Rate': {
        prop: 'cpkRate',
        type: String,
      },
      'CPK Current ODO': {
        prop: 'cpkCurrentOdometer',
        type: String,
      },
      'CPK Opening Odo': {
        prop: 'cpkOpeningOdometer',
        type: String,
      },
      'Standard Rate (Y/N)': {
        prop: 'standardRate',
        type: String,
      },
      'CPK Current Date': {
        prop: 'cpkCurrentDate',
        type: String,
      },
      'Rate Amount': {
        prop: 'rateAmount',
        type: String,
      },
      'Add vehicle Message (Y/N)': {
        prop: 'addVehicleMessage',
        type: String,
      },
      'Message Line 1': {
        prop: 'messageLine1',
        type: String,
      },
      'Message Line 2': {
        prop: 'messageLine2',
        type: String,
      },
      'Message Line 3': {
        prop: 'messageLine3',
        type: String,
      },
      'Message Line 4': {
        prop: 'messageLine4',
        type: String,
      },
      'Message Line 5': {
        prop: 'messageLine5',
        type: String,
      },
      'Message Line 6': {
        prop: 'messageLine6',
        type: String,
      },
      'Message Line 7': {
        prop: 'messageLine7',
        type: String,
      },
      'Message Line 8': {
        prop: 'messageLine8',
        type: String,
      },
      'Message Line 9': {
        prop: 'messageLine9',
        type: String,
      },
      'Message Line 10': {
        prop: 'messageLine10',
        type: String,
      },
      'Message Line 11': {
        prop: 'messageLine11',
        type: String,
      },
      'Message Line 12': {
        prop: 'messageLine12',
        type: String,
      },
      'Message Line 13': {
        prop: 'messageLine13',
        type: String,
      },
      'Message Line 14': {
        prop: 'messageLine14',
        type: String,
      },
      'Message Line 15': {
        prop: 'messageLine15',
        type: String,
      },
      'Message Line 16': {
        prop: 'messageLine16',
        type: String,
      },
      'Message Line 17': {
        prop: 'messageLine17',
        type: String,
      },
      'Message Line 18': {
        prop: 'messageLine18',
        type: String,
      },
      'Message Line 19': {
        prop: 'messageLine19',
        type: String,
      },
      'Message Line 20': {
        prop: 'messageLine20',
        type: String,
      },
      'Std Cpk Rate': {
        prop: 'stdCpkRate',
        type: String,
      },
    },
  },
};
