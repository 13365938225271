<template>
  <v-container>
    <v-row :class="{'mb-5': !dense}"
           justify="center">
      <div>
        <div class="text-caption text-center">
          {{ message }}
        </div>
        <div v-if="subMessage"
             class="text-body-2 text-center">
          {{ subMessage }}
        </div>
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: '',
    },
    subMessage: {
      type: String,
      default: '',
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
